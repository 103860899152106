<template>
  <a
    class="hover:scale-105 transition-transform"
    :style="computedHeight"
    :href="url"
    target="_blank"
  >
    <img
      :style="computedHeight"
      :src="require(`@/assets/img/${img}`)"
      alt="logo"
    />
  </a>
</template>

<script>
export default {
  name: "Logo",
  props: {
    height: {
      type: String,
    },
    url: {
      type: String,
    },
    img: {
      type: String,
    },
  },
  computed: {
    computedHeight() {
      return `max-height: ${this.height}`;
    },
  },
};
</script>
